import { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { Link } from '@remix-run/react'
import classNames from 'clsx'

import {
  ArrowLeftOnRectangleIcon,
  BuildingLibraryIcon,
  CheckCircleIcon,
  Cog8ToothIcon,
  ComputerDesktopIcon,
  EllipsisVerticalIcon,
  MoonIcon,
  PaintBrushIcon,
  Squares2X2Icon,
  SunIcon,
} from '@heroicons/react/24/outline'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '~/core/ui/Dropdown'

import configuration from '~/configuration'
import ProfileAvatar from '~/components/ProfileAvatar'
import type UserSession from '~/core/session/types/user-session'

import {
  DARK_THEME_CLASSNAME,
  LIGHT_THEME_CLASSNAME,
  SYSTEM_THEME_CLASSNAME,
  getStoredTheme,
  switchTheme,
} from '~/core/theming'

import If from '~/core/ui/If'
import GlobalRole from '~/core/session/types/global-role'
import useUser from '~/core/hooks/use-user'

const ProfileDropdown: React.FCC<{
  userSession: Maybe<UserSession>
  signOutRequested: () => unknown
  className?: string
  displayName?: boolean
}> = ({ userSession, signOutRequested, className, displayName = true }) => {
  const { data: user } = useUser({
    enabled: true,
    refetchOnMount: false,
  })

  const userDisplayName = userSession?.data?.displayName

  const signedInAsLabel = useMemo(() => {
    const auth = userSession?.auth
    const email = auth?.email
    const phone = auth?.phone

    return email || phone
  }, [userSession])

  const isSuperAdmin = useMemo(() => {
    return user?.app_metadata.role === GlobalRole.SuperAdmin
  }, [user])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        aria-label="Open your profile menu"
        data-cy="profile-dropdown-trigger"
        className={classNames(
          'flex cursor-pointer focus:outline-none group items-center',
          className,
          {
            ['items-center space-x-2.5 rounded-lg border border-gray-100'
            + ' dark:border-dark-900 p-2 transition-colors'
            + ' hover:bg-gray-50 dark:hover:bg-dark-800/40']: displayName,
          },
        )}
      >
        <ProfileAvatar user={userSession} />

        <If condition={displayName}>
          <div className="flex w-full flex-col truncate text-left">
            <span className="truncate text-sm">{userDisplayName}</span>

            <span
              className="truncate text-xs text-gray-500 dark:text-gray-400"
            >
              {signedInAsLabel}
            </span>
          </div>

          <EllipsisVerticalIcon
            className="flex size-8 text-gray-500/70 group-hover:text-gray-500"
          />
        </If>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className="!min-w-60"
        collisionPadding={{ left: 25, right: 25 }}
        sideOffset={20}
      >
        <DropdownMenuItem className="!h-10 rounded-none">
          <div
            className="flex flex-col justify-start truncate text-left text-xs"
          >
            <div className="text-gray-500">Signed in as</div>

            <div>
              <span className="block truncate">{signedInAsLabel}</span>
            </div>
          </div>
        </DropdownMenuItem>

        <DropdownMenuSeparator />

        <DropdownMenuItem>
          <Link
            className="flex size-full items-center space-x-2"
            to={configuration.paths.appHome}
          >
            <Squares2X2Icon className="h-5" />
            <span>
              <Trans i18nKey="common:dashboardTabLabel" />
            </span>
          </Link>
        </DropdownMenuItem>

        <DropdownMenuItem asChild>
          <Link
            className="flex items-center space-x-2"
            to="/settings/profile"
          >
            <Cog8ToothIcon className="h-5" />
            <span>
              <Trans i18nKey="common:settingsTabLabel" />
            </span>
          </Link>
        </DropdownMenuItem>

        <If condition={configuration.features.enableThemeSwitcher}>
          <ThemeSelectorSubMenu />
        </If>

        <DropdownMenuSeparator />

        <If condition={isSuperAdmin}>
          <DropdownMenuItem asChild>
            <Link className="flex items-center space-x-2" to="/admin">
              <BuildingLibraryIcon className="h-5" />

              <span>Admin</span>
            </Link>
          </DropdownMenuItem>

          <DropdownMenuSeparator />
        </If>

        <DropdownMenuItem
          role="button"
          className="cursor-pointer"
          onClick={signOutRequested}
        >
          <span className="flex w-full items-center space-x-2">
            <ArrowLeftOnRectangleIcon className="h-5" />

            <span>
              <Trans i18nKey="auth:signOut" />
            </span>
          </span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

function ThemeSelectorSubMenu() {
  const currentTheme = useMemo(() => getStoredTheme(), [])
  const Wrapper: React.FCC = ({ children }) => (
    <span className="flex items-center space-x-2.5">{children}</span>
  )

  return (
    <>
      <DropdownMenuSeparator className="hidden lg:flex" />

      <DropdownMenuSub>
        <DropdownMenuSubTrigger className="hidden lg:flex">
          <Wrapper>
            <PaintBrushIcon className="h-5" />

            <span>
              <Trans i18nKey="common:theme" />
            </span>
          </Wrapper>
        </DropdownMenuSubTrigger>

        <DropdownMenuSubContent>
          <DropdownMenuItem
            className="flex cursor-pointer items-center justify-between"
            onClick={() => switchTheme(LIGHT_THEME_CLASSNAME)}
          >
            <Wrapper>
              <SunIcon className="h-4" />

              <span>
                <Trans i18nKey="common:lightTheme" />
              </span>
            </Wrapper>

            <If condition={currentTheme === LIGHT_THEME_CLASSNAME}>
              <CheckCircleIcon className="h-5" />
            </If>
          </DropdownMenuItem>

          <DropdownMenuItem
            className="flex cursor-pointer items-center justify-between"
            onClick={() => switchTheme(DARK_THEME_CLASSNAME)}
          >
            <Wrapper>
              <MoonIcon className="h-4" />

              <span>
                <Trans i18nKey="common:darkTheme" />
              </span>
            </Wrapper>

            <If condition={currentTheme === DARK_THEME_CLASSNAME}>
              <CheckCircleIcon className="h-5" />
            </If>
          </DropdownMenuItem>

          <DropdownMenuItem
            className="flex cursor-pointer items-center justify-between"
            onClick={() => switchTheme(SYSTEM_THEME_CLASSNAME)}
          >
            <Wrapper>
              <ComputerDesktopIcon className="h-4" />

              <span>
                <Trans i18nKey="common:systemTheme" />
              </span>
            </Wrapper>

            <If condition={currentTheme === SYSTEM_THEME_CLASSNAME}>
              <CheckCircleIcon className="h-5" />
            </If>
          </DropdownMenuItem>
        </DropdownMenuSubContent>
      </DropdownMenuSub>
    </>
  )
}

export default ProfileDropdown
